import React, { useState } from "react"
// import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Modal from "react-modal"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReactMarkdown from "react-markdown"

import { Button } from "../buttons/buttons"
import { Section, Container } from "../grid/grid"
import { ImgRatio, BackgroundImage } from "../utils/utils"

import imgArrowDown from "../cover/img/arrow_down.png"

export const Cover = ({
  variant,
  theme,
  breadcrumb,
  img,
  imgMd,
  imgHeight,
  imgOverlay,
  label,
  labelClassName,
  smallText,
  title,
  titleLine1,
  titleLine2,
  titleClassName,
  text,
  textClassName,
  buttons,
  contentMaxWidth,
  className,
  children,
  scrollHint,
  scrollHintLink,
  scrollHintText,
  videoType,
  videoSrc,
  videoAudio,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const modalClick = arg => {
    switch (arg) {
      case "open":
        setIsOpen(true)
        break

      case "close":
        setIsOpen(false)
        break

      default:
        return null
    }
  }

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
    },
  }

  return (
    <div className={`cover cover-${variant} ${theme} ${className}`}>
      <div className={`cover-bg-wrapper`}>
        {videoType === "video" ? (
          <div className="video-background">
            {/* prettier-ignore */}
            <video playsInline autoPlay muted={videoAudio} loop className={imgHeight}>
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        ) : videoType === "video-popup" ? (
          <div className="cover-video">
            {/* prettier-ignore */}
            <BackgroundImage Tag="div" fluid={img} className={`cover-bg ${imgHeight}`}>
              <div className={`bg-overlay bg-overlay-${imgOverlay}`}></div>
              <ImgRatio />
            </BackgroundImage>
            {/* prettier-ignore */}
            <div className={`vid-pulse`} type="button" onClick={() => modalClick("open")}>
              <div className="vid-play">
                <i className="far fa-play" />
              </div>
            </div>
            {/* prettier-ignore */}
            <Modal isOpen={modalIsOpen} onRequestClose={() => modalClick("close")} className="modal-centered modal-xl">
              <div className="video-background">
                {/* <Video id="cover-video" muted={videoAudio} className="w-100" videoSrc={videoSrc}  /> */}
              </div>
            </Modal>
          </div>
        ) : (
          <div>
            {/* Image responsive for MD Up*/}
            <BackgroundImage
              Tag="div"
              fluid={img}
              className={`cover-bg ${imgHeight} d-block d-md-none`}
            >
              <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              <ImgRatio />
            </BackgroundImage>
            <BackgroundImage
              Tag="div"
              fluid={imgMd ? imgMd : img}
              className={`cover-bg ${imgHeight} d-none d-md-block`}
            >
              <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              <ImgRatio />
            </BackgroundImage>
          </div>
        )}
      </div>
      {breadcrumb && <div className="breadcrumb-wrapper">{breadcrumb}</div>}
      <div className="cover-body">
        <div className="container">
          <div className={`cover-content ${contentMaxWidth}`}>
            {label && (
              <div className="overflow-hidden">
                <p className={`cover-label ${labelClassName}`}>{label}</p>
              </div>
            )}
            {title && (
              <div className="overflow-hidden">
                <h1 className={`cover-title ${titleClassName}`}>
                  {title}{" "}
                  <span className="small-text d-block">{smallText}</span>
                </h1>
              </div>
            )}
            {titleLine1 && titleLine2 && (
              <h1 className={`cover-title ${titleClassName}`}>
                <div className="overflow-hidden mb-1">
                  <span className="d-block">{titleLine1}</span>
                </div>
                <div className="overflow-hidden">
                  <span className="d-block">{titleLine2}</span>
                </div>
              </h1>
            )}
            {text && (
              <div className="overflow-hidden">
                <ReactMarkdown
                  source={text}
                  escapeHtml={false}
                  className={`cover-text ${textClassName}`}
                />
              </div>
            )}
            {buttons && (
              <div className="btn-group">
                {buttons.map((button, i) => {
                  return (
                    <div className="overflow-hidden" key={i}>
                      <Button
                        variant={button.variant || "primary"}
                        link={button.link.url}
                        className={button.className || ""}
                      >
                        {button.text}
                      </Button>
                    </div>
                  )
                })}
              </div>
            )}
            {children}
          </div>
        </div>
      </div>

      {scrollHint && (
        <div
          onClick={() => scrollTo(scrollHintLink)}
          className="scroll-hint w-text"
        >
          <img src={imgArrowDown} alt="Scroll hint" />
          <p>{scrollHintText}</p>
        </div>
      )}
    </div>
  )
}

Cover.propTypes = {
  variant: PropTypes.oneOf(["basic", "responsive", "fluid"]),
  theme: PropTypes.oneOf(["", "light", "dark"]),
  breadcrumb: PropTypes.element,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .cover-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.any, // Set cover overlay %
  smallText: PropTypes.string,
  title: PropTypes.string,
  titleLine1: PropTypes.string,
  titleLine2: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.any),
  contentMaxWidth: PropTypes.string, // Set .cover-content max width (with sizing utilities classes (e.g. .mw-md-500px, .mw-100, etc.)
  className: PropTypes.string,
  children: PropTypes.any,
  scrollHint: PropTypes.bool,
  scrollHintLink: PropTypes.string,
  scrollHintText: PropTypes.string,
  videoType: PropTypes.oneOf(["", "video", "video-popup"]),
  videoSrc: PropTypes.any,
  videoAudio: PropTypes.bool,
}

Cover.defaultProps = {
  variant: "basic",
  theme: "",
  breadcrumb: null,
  contentMaxWidth: "mw-md-500px",
  imgHeight: "h-400px h-xs-500px h-md-600px",
  imgOverlay: 0,
  titleClassName: "",
  textClassName: "",
  buttons: [],
  className: "",
  videoType: "",
  videoSrc: "",
  videoAudio: false,
  scrollHint: false,
  scrollHintLink: "#nextSection",
  scrollHintText: "Scroll down",
}

// Sample of usage

// <Cover
//    videoType="video-popup"
//    videoSrc="mlHklH5VBtI"
//    videoSrc="https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
//    videoAudio={false}
// >

// Cover with content inside
//
// Usually used for cover within pages (not on the top of the page)
export const CoverContent = ({
  theme,
  img,
  imgHeight,
  imgOverlay,
  contentMaxWidth,
  className,
  children,
  forwardRef,
}) => {
  return (
    <div ref={forwardRef} className={`cover ${theme} ${className}`}>
      <div className={`cover-bg-wrapper`}>
        <BackgroundImage
          Tag="div"
          fluid={img}
          className={`cover-bg ${imgHeight}`}
        >
          <Section className="cover-body position-relative">
            <Container>
              <div className={`cover-content ${contentMaxWidth}`}>
                {children}
              </div>
            </Container>
          </Section>
          <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
          <ImgRatio />
        </BackgroundImage>
      </div>
    </div>
  )
}

CoverContent.propTypes = {
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  img: PropTypes.any, // Set cover background image
  imgHeight: PropTypes.string, // Set .cover-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.any, // Set cover overlay %
  contentMaxWidth: PropTypes.string, // Set .cover-content max width (with sizing utilities classes (e.g. .mw-md-500px, .mw-100, etc.)
  className: PropTypes.string, // Set cover optional classes
  children: PropTypes.any,
}

CoverContent.defaultProps = {
  theme: "",
  contentMaxWidth: "mw-md-500px",
  imgHeight: "h-auto",
  imgOverlay: 0,
  className: "",
}
